<template>
  <div>
    <img src="../../assets/images/zwkf.jpeg" alt="">
  </div>
</template>

<script>
export default {
  name: 'Others'
}
</script>

<style lang="less" scoped>
  div {
    img {
      width: 900px;
      height: 455px;
      display: block;
    }
  }
</style>